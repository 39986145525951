import { graphql, useStaticQuery } from 'gatsby';

export const useAthletesHOFQuery = () => {
  const { athletes } = useStaticQuery(graphql`
    {
      athletes: allAirtable(
        filter: { data: { hall_of_fame_type: { eq: "Athlete" } } }
        sort: { order: DESC, fields: data___induction_year }
      ) {
        nodes {
          data {
            last_name
            full_name
            hof_id
            bio
            school
            class_year
            events
            gender
            induction_year
            attachments {
              localFiles {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return { athletes };
};
