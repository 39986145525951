import React from 'react';

// Components
import { FilterBar } from 'components/FilterBar';

// Layouts
import { PageLayout, SEOContent } from 'layouts';

// Templates
import { BasicTemplate, HallOfFameCards } from 'templates';

// GraphQL
import { useCoachesHOFQuery } from 'graphql';

// Types
import { HOF } from 'src/types';
interface Props {
  data: {
    coaches: HOF;
  };
}

const FILTERS = [
  { title: 'Last Name', gqlKey: 'last_name' },
  { title: 'School', gqlKey: 'school' },
  { title: 'Induction Year', gqlKey: 'induction_year' },
];

const title = 'Coaches Hall of Fame';

const CoachesHOFPageTemplate = () => {
  const { coaches } = useCoachesHOFQuery();

  return (
    <SEOContent>
      <PageLayout>
        <BasicTemplate templateTitle={title}>
          <FilterBar
            data={coaches}
            filters={FILTERS}
            render={tableData => (
              <HallOfFameCards data={tableData} linkPrefix="coaches" />
            )}
          />
        </BasicTemplate>
      </PageLayout>
    </SEOContent>
  );
};

export default CoachesHOFPageTemplate;
