import { graphql, useStaticQuery } from "gatsby";

export const useMeetsQuery = () => {
  const { meets } = useStaticQuery(graphql`
    query {
      meets: allAirtable(
        filter: { table: { eq: "Meets" }, data: { is_active: { eq: true } } }
        sort: { fields: data___meet_date, order: ASC }
      ) {
        nodes {
          data {
            dayNumber: meet_date(formatString: "Do")
            is_active
            meet_name
            meet_slug
            meet_date
            month: meet_date(formatString: "MMM")
            result_link
            result_pdfs {
              id
              url
              thumbnails {
                small {
                  url
                }
              }
            }
            season
            venue
            weekDay: meet_date(formatString: "ddd")
            year: meet_date(formatString: "YYYY")
            performance_lists {
              id
            }
            meet_information {
              id
            }
            meet_information_url
            performance_lists_url
            meet_maps_url
            result_pdfs_url
          }
        }
      }
    }
  `);

  return { meets };
};
