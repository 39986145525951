import { graphql, useStaticQuery } from 'gatsby';

export const useCoachesHOFQuery = () => {
  const { coaches } = useStaticQuery(graphql`
    {
    coaches: allAirtable(
      filter: { data: { hall_of_fame_type: { eq: "Coach" } } }
      sort: { order: DESC, fields: data___induction_year }
    ) {
      nodes {
        data {
          last_name
          full_name
          hof_id
          bio
          school
          gender
          induction_year
          attachments {
            localFiles {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
  `);

  return { coaches };
};
